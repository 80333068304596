<template>
  <div
    class="completion-circle"
    :style="{ height: `${size}px`, width: `${size}px` }"
  >
    <svg :height="size" :width="size" :stroke-width="lineSize">
      <circle
        :r="radius"
        :cx="coordinates"
        :cy="coordinates"
        fill="transparent"
        :stroke-dasharray="calculateCircleBorder"
        :stroke-dashoffset="0"
      ></circle>
      <circle
        id="bar"
        :r="radius"
        :cx="coordinates"
        :cy="coordinates"
        fill="transparent"
        :stroke-dasharray="calculateCircleBorder"
        :stroke-dashoffset="totalProgressBorder"
      ></circle>
    </svg>
    <div class="fill-content" v-if="hasIcon">
      <img
        :height="`${iconSize}px`"
        :width="`${iconSize}px`"
        src="../../../assets/spartan_logo.svg"
        alt=""
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ["size", "lineSize", "completed", "total", "hasIcon"],
  computed: {
    calculateCircleBorder() {
      return 2 * Math.PI * this.radius;
    },
    totalProgressBorder() {
      return (this.calculateCircleBorder * (100 - this.progress)) / 100;
    },
    progress() {
      return Math.floor((100 * this.completed) / this.total);
    },
    coordinates() {
      return this.size / 2;
    },
    radius() {
      return this.coordinates - this.lineSize / 2;
    },
    iconSize() {
      return this.size - this.lineSize;
    },
  },
};
</script>
<style lang="scss" scoped>
.completion-circle {
  display: inherit;
  position: relative;

  svg {
    transform: rotate(-90deg);
    stroke: #cc2930;

    position: relative;
    z-index: 5;

    circle {
      transition: stroke-dashoffset 200ms linear;
    }

    #bar {
      stroke: #5ac43b;
    }
  }
  .fill-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    z-index: 1;
    img {
      display: inherit;
    }
  }
}
</style>