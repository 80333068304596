<template>
  <div class="result-view" v-if="!resultLoading">
    <div class="back-button" @click="$router.push({ name: 'results' })">
      <i class="material-icons">keyboard_arrow_left</i>
      <span>{{ $t("back") }}</span>
    </div>

    <div class="result-date">
      <span>{{ eventDate }}</span>
      <v-slide-x-transition>
        <div class="result-custom-rate" v-if="resultData.feedback">
          <span
            class="material-icons"
            :class="{ 'is-filled': resultData.feedback.rating >= value }"
            v-for="(value, index) in [1, 2, 3, 4, 5]"
            :key="index"
          >
            star_rate
          </span>
        </div>
      </v-slide-x-transition>
    </div>

    <div class="result-title">
      <span class="mem-font--page-view__title">{{ result.event_name }}</span>
      <div class="share-options" v-if="canShare">
        <mem-button
          class="result-share-top-btn"
          btn-type="secondary-dark"
          @click="showShareDialog"
          >{{ $t("share_your_result") }}</mem-button
        >
      </div>
    </div>

    <v-expand-transition>
      <div class="feedback-section" v-if="showFeedbackForm">
        <div class="section-subtitle">
          {{ $t("result_feedback_section_title") }}
        </div>

        <feedback-form v-on:submit="onFeedbackSubmit" />
      </div>
    </v-expand-transition>

    <!-- photos -->
    <result-photos
      v-if="showPhotos"
      :userPhotos="resultPhotos"
      :bonusPhotos="resultData.bonusPhotos"
      :highlights="!!resultData.highlight_photos"
      :bib="result.bib_number"
      :eventId="result.event_id"
      :canDownload="canDownload"
      :country="userData.spartan_country_code"
      :photosInfo="resultPhotosInfo"
    />

    <!-- result info grid -->
    <div class="result-info">
      <div class="category-header">
        <div class="section-subtitle">
          <span>{{ $t("your_result") }}</span>
          <span> - {{ heat }}</span>
          <span> {{ $t("heat") }}</span>
        </div>
        <a
          :href="leaderBoardUrl"
          class="component-btn leaderboard-btn"
          target="_blank"
        >
          {{ $t("leaderboard") }}
        </a>
      </div>
      <div class="result-data">
        <div class="default-view">
          <div class="view-row">
            <div class="data-item result-time">
              <div class="result-subtitle">{{ $t("time") }}</div>
              <div class="item-text">{{ result.completion_time }}</div>
            </div>
            <div class="data-item result-percentile">
              <div class="result-subtitle">{{ $t("percentile") }}</div>
              <div class="item-text">{{ percentile }}</div>
            </div>
            <div class="data-item result-distance">
              <div class="result-subtitle">{{ $t("distance") }}</div>
              <div class="item-text">{{ result.event_distance_km }} KM</div>
            </div>
            <div class="data-item result-category">
              <div class="result-subtitle">{{ $t("category_rank") }}</div>
              <div class="item-text">
                <span>{{ result.rank_overall }}</span>
                <span class="total-count"
                  >/{{
                    result.count_overall ? result.count_overall : "--"
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="view-row">
            <div class="data-item result-gender">
              <div class="result-subtitle">{{ $t("by_gender") }}</div>
              <div class="item-text">
                <span>{{ result.rank_gender }}</span>
                <span class="total-count"
                  >/{{ result.count_gender ? result.count_gender : "--" }}</span
                >
              </div>
            </div>
            <div class="data-item result-age">
              <div class="result-subtitle">
                {{ $t("age") }} {{ result.age_group }}
              </div>
              <div class="item-text">
                <span>{{ result.rank_age_group }}</span>
                <span class="total-count"
                  >/{{
                    result.count_age_group ? result.count_age_group : "--"
                  }}</span
                >
              </div>
            </div>
            <div class="data-item result-extra">
              <template v-if="!canShare">
                <div>{{ $t("you_can_go_faster") }}</div>
                <find-race-button class="component-btn" :text="'register'" />
              </template>

              <div v-else class="bottom-share-options">
                <mem-button
                  class="result-share-bottom-btn"
                  btn-type="secondary-dark"
                  @click="showShareDialog"
                  >{{ $t("share_your_result") }}</mem-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="compact-view">
          <div class="view-row">
            <div class="data-item result-time">
              <div class="result-subtitle">{{ $t("time") }}</div>
              <div class="item-text">{{ result.completion_time }}</div>
            </div>
            <div class="data-item result-category">
              <div class="result-subtitle">{{ $t("category_rank") }}</div>
              <div class="item-text">
                <span>{{ result.rank_overall }}</span>
                <span class="total-count"
                  >/{{
                    result.count_overall ? result.count_overall : "--"
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="view-row">
            <div class="data-item result-percentile">
              <div class="result-subtitle">{{ $t("percentile") }}</div>
              <div class="item-text">{{ percentile }}</div>
            </div>
            <div class="data-item result-gender">
              <div class="result-subtitle">{{ $t("by_gender") }}</div>
              <div class="item-text">
                <span>{{ result.rank_gender }}</span>
                <span class="total-count"
                  >/{{ result.count_gender ? result.count_gender : "--" }}</span
                >
              </div>
            </div>
          </div>
          <div class="view-row">
            <div class="data-item result-distance">
              <div class="result-subtitle">{{ $t("distance") }}</div>
              <div class="item-text">{{ result.event_distance_km }} KM</div>
            </div>
            <div class="data-item result-age">
              <div class="result-subtitle">
                {{ $t("age") }} {{ result.age_group }}
              </div>
              <div class="item-text">
                <span>{{ result.rank_age_group }}</span>
                <span class="total-count"
                  >/{{
                    result.count_age_group ? result.count_age_group : "--"
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="view-row">
            <div class="data-item result-extra">
              <template v-if="!canShare">
                <div>{{ $t("you_can_go_faster") }}</div>
                <find-race-button class="component-btn" :text="'register'" />
              </template>
              <div v-else class="bottom-share-options">
                <mem-button
                  class="result-share-bottom-btn"
                  btn-type="secondary-dark"
                  @click="showShareDialog"
                  >{{ $t("share_your_result") }}</mem-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- obstacles -->
    <result-obstacles :eventId="resultData.result.event_id" />

    <!-- stats and trophies -->
    <div class="result-stats-and-trophies">
      <div class="result-stats">
        <div class="section-subtitle">{{ $t("race_stats") }}</div>
        <div class="stats-table">
          <div v-if="resultData.result.event_distance_km" class="row-name">
            {{ $t("distance") }}
          </div>
          <div v-if="resultData.result.event_distance_km">
            {{ resultData.result.event_distance_km }} {{ $t("km") }}
          </div>
          <!-- <div class="row-name">{{ $t("elevation_gain") }}</div>
          <div></div>
          <div class="row-name">{{ $t("altitude") }}</div>
          <div></div> -->

          <div v-if="resultData.result.event_num_obstacles" class="row-name">
            {{ $t("obstacles") }}
          </div>
          <div v-if="resultData.result.event_num_obstacles">
            {{ resultData.result.event_num_obstacles }}
          </div>

          <div v-if="eventTemperature" class="row-name">
            {{ $t("temperature") }}
          </div>
          <div v-if="eventTemperature">
            <span>{{ eventTemperature }}&deg; </span>
            <span v-if="userData && userData.spartan_country_code !== 'US'"
              >C</span
            >
            <span v-if="userData && userData.spartan_country_code === 'US'"
              >F</span
            >
          </div>

          <div v-if="resultData.weather.conditions" class="row-name">
            {{ $t("weather") }}
          </div>
          <div v-if="resultData.weather.conditions">
            {{ resultData.weather.conditions }}
          </div>
        </div>
      </div>
      <div class="result-trophies">
        <div class="section-subtitle">{{ $t("trophies") }}</div>
        <div
          v-if="
            resultData.earned_badges && resultData.earned_badges.length !== 0
          "
        >
          <div
            class="trophies-table"
            v-for="(trophy, index) in resultData.earned_badges"
            v-bind:key="index"
          >
            <div class="badge-icon">
              <img :src="trophy.badge_icon_url" alt="badge icon" />
            </div>
            <div>{{ trophy.badge_name }}</div>
          </div>
        </div>
        <div
          class="no-trophies"
          v-if="
            !resultData.earned_badges ||
            (resultData.earned_badges && resultData.earned_badges.length === 0)
          "
        >
          <div>{{ $t("result_no_trophies") }}</div>
        </div>
      </div>
    </div>

    <!-- team results table/list-->
    <div class="team-results" v-if="resultData.team">
      <div class="team-name">
        <img
          class="team-avatar"
          :src="resultData.team.avatar || TeamDefaultAvatar"
          alt=""
        />
        <div class="section-subtitle">{{ resultData.team.name }}</div>
      </div>
      <leaderboard-table :resultId="$route.params.id" />
    </div>

    <!-- share dialog -->
    <template v-if="canShare">
      <share-dialog
        v-model="shareDialog"
        :result-id="$route.params.id"
        :renderKey="shareDialogKey"
        :max-width="480"
        v-on:close="shareDialog = false"
        v-on:share="pushDataLayerEvent"
      ></share-dialog>
    </template>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import FindRaceButton from "@/components/FindRaceButton.vue";
import ResultObstacles from "./components/ResultObstacles.vue";
import TeamDefaultAvatar from "@/assets/team-card-avatar.svg";
import localizeResolver from "@/common/mixins/localizeResolver";
import { UrlPath } from "@/common/helpers";

export default {
  name: "ResultView",
  mixins: [localizeResolver],
  data: () => ({
    resultLoading: true,
    shareDialog: false,
    shareDialogKey: 0,
    TeamDefaultAvatar,
    showFeedbackForm: true,
  }),
  components: {
    "find-race-button": FindRaceButton,
    "feedback-form": () => import("./components/ResultFeedbackForm.vue"),
    "result-photos": () => import("./components/ResultPhotos.vue"),
    ResultObstacles,
    LeaderboardTable: () => import("./components/ResultLeaderboardTable.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "share-dialog": () => import("./components/DialogShareResult.vue"),
  },
  computed: {
    ...mapGetters([
      "resultData",
      "specificLinks",
      "userData",
      "subscriptionStatus",
    ]),
    // TODO: remove it
    result() {
      return this.resultData.result || {};
    },
    eventDate() {
      if (!this.resultData.event_date) return "";

      return moment(this.resultData.event_date, "YYYY-MM-DD").format(
        "MMM DD, YYYY"
      );
    },
    eventTemperature() {
      const KELVIN = 273.15;
      function CELSIUS_TO_FAHRENHEIT(temp) {
        return Math.round((temp * 9) / 5 + 32);
      }

      let { weather } = this.resultData;
      if (!weather && !weather.temperature) return;

      // Server sends temperature in Kelvins
      let tempCelsius = Math.round(weather.temperature - KELVIN);
      if (this.userData?.spartan_country_code === "US")
        return CELSIUS_TO_FAHRENHEIT(tempCelsius);

      return tempCelsius;
    },
    canDownload() {
      return (
        this.resultPhotosInfo.sportograf_purchase_status ===
          "pending_production" ||
        this.resultPhotosInfo.sportograf_purchase_status ===
          "complete_production"
      );
      // return this.subscriptionStatus === "active";
    },
    percentile() {
      if (!this.result.rank_overall || !this.result.count_overall) return "--";
      let result = Math.round(
        (this.result.rank_overall / this.result.count_overall) * 100
      );
      return `${result} %`;
    },
    heat() {
      if (!this.result.normalized_heat) return "";
      return this.result.normalized_heat.replace("_", " ");
    },
    resultPhotos() {
      let { photos, highlight_photos, event_photos_sync_type } =
        this.resultData;

      if (event_photos_sync_type === "facial_recognition") return photos;

      return photos || highlight_photos || [];
    },
    resultPhotosInfo() {
      let result_id = this.$route.params.id;
      let {
        photo_provider,
        sportograf_status,
        sportograf_selfie_uploaded,
        sportograf_purchase_status,
        sportograf_login_link,
        ticket_addons,
        venue,
      } = this.resultData;

      return {
        result_id,
        photo_provider,
        sportograf_status,
        sportograf_selfie_uploaded,
        sportograf_purchase_status,
        sportograf_login_link,
        photoAddonDetails: {
          price: ticket_addons.photo_package_price_after,
          currency: ticket_addons.currency,
          country: venue.country,
        },
      };
    },
    showPhotos() {
      // Events prior to 2024
      if (
        this.resultData.photo_provider !== "sportograf" &&
        this.resultPhotos.length === 0
      )
        return;

      // New events
      return true;
    },
    leaderBoardRelativePath() {
      const relativeUrl =
        this.localizeConfig.leaderboardUrl || "/race/past-results/[raceID]";
      return relativeUrl.replace("[raceID]", this.result.race_id);
    },
    leaderBoardUrl() {
      const homeUrl = this.localizeConfig?.homeUrl || window.location.hostname;
      return UrlPath.join(this.leaderBoardRelativePath, homeUrl);
    },
    canShare() {
      if (!this.isMobile) return;
      if (!navigator.share) return;

      let { event_date } = this.resultData;
      let eventYear = moment(event_date, "YYYY-MM-DD").year();

      if (!eventYear) return;
      if (eventYear < 2022) return;

      return true;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    // showFeedbackForm() {
    //   let { feedback } = this.resultData;
    //   if (feedback && feedback.rating) return;
    //   return this.hasFeedback;
    // },
  },
  methods: {
    ...mapActions([
      "getResult",
      "getObstaclesList",
      "submitResultFeedback",
    ]),
    showShareDialog() {
      this.shareDialogKey++;
      this.shareDialog = true;
      this.pushDataLayerEvent("resultShareVisible");
    },
    pushDataLayerEvent(event) {
      if (!window.dataLayer) return;

      return window.dataLayer.push({
        event,
        accountId: this.userData.id,
        raceName: this.result.event_name,
      });
    },
    async onFeedbackSubmit(data) {
      setTimeout(() => {
        this.showFeedbackForm = false;
      }, 2000);
      try {
        let { id: resultId } = this.$route.params;
        await this.submitResultFeedback({
          resultId,
          data,
        });
      } catch (error) {
        console.log("Something went wrong `(0_0)~", error);
      }
    },
  },
  async mounted() {
    this.resultLoading = true;
    let { eventId, id: resultId } = this.$route.params;
    try {
      await this.getResult(resultId);
      // feedback check
      let { feedback } = this.resultData;
      if (feedback && feedback.rating > 0) this.showFeedbackForm = false;
      //
      if (!eventId)
        this.$router.push({
          name: "resultPage",
          params: { eventId: this.result.event_id, id: resultId },
        });
      this.resultLoading = false;
      // console.log("%cResult ", "color:coral", this.resultData);
    } catch (err) {
      console.log(err);
    }
  },
};
</script>
<style lang="scss" scoped>
.result-view {
  max-width: 670px;
  padding-bottom: 200px;
  .share-options {
    margin-top: 30px;
    // margin-bottom: 55px;
    @media screen and (max-width: $mobile) {
      margin-top: 20px;
      // padding-left: 16px;
      // padding-right: 16px;
    }
  }
  .bottom-share-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mobile) {
      align-items: flex-start;
    }
  }
  .result-share-top-btn,
  .result-share-bottom-btn {
    height: 36px;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.04em;
  }
  .section-subtitle {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .component-btn {
    height: 37px;
    padding: 0 16px;

    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.04em;
  }
  .leaderboard-btn {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
    border-radius: 22.7053px;
    transition: box-shadow 0.18s ease-in-out;

    &:hover {
      color: #191818;
      background: #ffffff;
      border: 1px solid #ffffff;
    }
  }
  .back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    margin-bottom: 24px;

    position: relative;
    font-weight: bold;
    font-size: 14px;
    @include cursorPointer;
    span {
      line-height: 14px;
      margin-left: 20px;
    }
    text-transform: uppercase;

    i {
      position: absolute;
      left: -8px;
    }
  }
  .result-date {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3px;
    .result-custom-rate {
      display: grid;
      grid-template-columns: repeat(5, max-content);
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      .material-icons {
        font-size: 12px;
      }
      .is-filled {
        color: #cf102d;
      }
    }
  }
  .result-title {
    @include elementMarginBottom("M");
    @media screen and (max-width: $mobile) {
    }
  }

  .feedback-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: $mobile) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .section-subtitle {
      width: 100%;
      @include elementMarginBottom("S");
      // padding-top: 8px;
      // border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .result-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    @media screen and (max-width: 499px) {
      margin-bottom: 47px;
    }
    .default-view {
      @media screen and (max-width: 499px) {
        display: none;
      }
      .view-row {
        display: grid;
        grid-template-columns: 127px repeat(3, 1fr);
        .result-extra {
          grid-column: -1/3;
        }
      }
    }
    .compact-view {
      display: none;
      @media screen and (max-width: 499px) {
        display: block;
      }
      .view-row {
        display: grid;
        grid-template-columns: 136px 1fr;
        .result-extra {
          grid-column: 1/3;
          margin-top: 24px;
          margin-bottom: 12px;
        }
      }
    }
    .view-row:not(:last-child) {
      border-bottom: 1px solid #2e2e2e;
    }

    .category-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 499px) {
        padding: 0 16px;
        flex-direction: column;
        align-items: flex-start;
        .component-btn {
          margin-top: 25px;
        }
      }
    }
    .result-data {
      padding: 7px 24px 13px 24px;
      margin-top: 20px;
      // margin-bottom: 60px;
      background-color: #121212;
      @media screen and (max-width: 499px) {
        padding: 3px 16px 20px 16px;
        margin-top: 30px;
        // margin-bottom: 47px;
      }
      .data-item {
        margin: 20px 0;
        @media screen and (max-width: 499px) {
          margin: 16px 0;
        }
        .result-subtitle {
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          letter-spacing: 0.02em;
          text-transform: capitalize;
          color: #757474;
          margin-bottom: 6px;
        }
        .item-text {
          font-size: 22px;
          line-height: 24px;
          font-weight: bold;
          .total-count {
            opacity: 0.3;
          }
        }
      }
      .data-item:not(:first-child) {
        border-left: 1px solid #2e2e2e;
        padding-left: 32px;
        @media screen and (max-width: 499px) {
          padding-left: 44px;
        }
      }
      .result-extra {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.2em;
      }
    }
  }
  .result-stats-and-trophies {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;

    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    padding-bottom: 60px;
    .section-subtitle {
      padding-top: 16px;
      padding-bottom: 8px;
    }
    .result-stats {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      .stats-table {
        display: grid;
        grid-template-columns: auto 1fr;
        padding-top: 16px;
        gap: 12px;
        .row-name {
          color: #9d9d9d;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
    .result-trophies {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      .trophies-table {
        padding-top: 16px;
        display: grid;
        grid-template-columns: 28px 1fr;
        gap: 16px;
        align-items: center;
        .badge-icon {
          height: 28px;
          img {
            height: 28px;
            width: 28px;
          }
        }
      }
      .no-trophies {
        height: 184px;
        background: #121212;
        margin-top: 16px;
        padding-left: 30px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          letter-spacing: 0.0025em;
          max-width: 200px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
  .team-results {
    border-top: 1px solid #353333;
    .team-name {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding-top: 15px;
      padding-bottom: 15px;
      @media screen and (max-width: 899px) {
        padding-bottom: 44px;
      }
      @media screen and (max-width: 499px) {
        padding-left: 16px;
        padding-right: 16px;
      }
      .team-avatar {
        height: 37px;
        width: 37px;
        border-radius: 50%;
        margin-right: 24px;
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .result-view {
    padding-top: 55px;
    .back-button {
      display: none;
    }
    .result-date,
    .result-title,
    .result-stats-and-trophies {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0;
      gap: 0;
      .result-stats,
      .result-trophies {
        padding-bottom: 60px;
      }
    }
    .result-stats-and-trophies {
      grid-template-columns: 1fr;
    }
  }
}
</style>
