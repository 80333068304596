<template>
  <div
    class="card"
    :class="['card-' + cardIndex]"
    :id="'item-' + cardIndex"
    :style="cardBackground"
  >
    <div class="mem-font--obstacle-card__title card-title">{{ $t(obstacle.name_i18n_key) }}</div>
    <div class="card-subline">{{ $t(obstacle.subline_i18n_key) }}</div>
  </div>
</template>
<script>
export default {
  props: ["obstacle", "cardIndex"],
  computed: {
    cardBackground() {
      return {
        "background-image": `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.82) 100%), url(${this.obstacle.image_url_full})`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background-size: cover;

  height: 350px;
  max-width: 670px;
  width: 100%;
  position: absolute;
  border-radius: 10px;
  bottom: 27px;
  opacity: 0;
  transform: scale(0.91) translateY(-50px);
  padding-bottom: 44px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .card-title {
    margin-bottom: 8px;
    text-align: center;
  }
  .card-subline {
    font-size: 17px;
    line-height: 25px;
    text-transform: uppercase;
  }
}
.card-0 {
  transform: scale(1) translateY(0px);
  z-index: 3;
  opacity: 1;
}
.card-1 {
  transform: scale(0.96) translateY(-20px);
  z-index: 2;
  opacity: 1;
}
.card-2 {
  transform: scale(0.91) translateY(-40px);
  z-index: 1;
  opacity: 0.3;
}
</style>