<template>
  <div
    class="result-obstacles"
    v-if="
      unreportedObstaclesList.length !== 0 || reportedObstacles.length !== 0
    "
  >
    <!-- Component header -->
    <div class="obstacles-header">
      <span>{{ $t("obstacle_results") }}</span>
      <div class="completion-progress">
        <div class="progress-counter">
          <span :style="{ color: completed !== 0 ? '#FFFFFF' : '' }">{{
            completed
          }}</span>
          <span>/{{ total }}</span>
        </div>
        <div
          class="progress-circle"
          v-if="!showResultsReport && unreportedObstaclesList.length === 0"
        >
          <completion-circle
            :size="30"
            :lineSize="4"
            :completed="completed"
            :total="total"
          />
        </div>
      </div>
    </div>
    <!-- Reporting state -->
    <div
      class="reporting-view"
      v-if="unreportedObstaclesList && unreportedObstaclesList.length !== 0"
    >
      <div class="cards-wrap">
        <template v-for="(obstacle, index) in unreportedObstaclesList">
          <obstacle-card :key="index" :obstacle="obstacle" :cardIndex="index" />
        </template>
        <div class="disider">
          <div class="disider-btn" @click="deny">
            <i class="material-icons" style="color: #cc092f">close</i>
          </div>
          <div class="disider-btn" @click="accept">
            <i class="material-icons" style="color: #5ac43b">done</i>
          </div>
        </div>
      </div>
    </div>
    <!-- Report is finished view -->
    <div class="results-report" v-if="showResultsReport">
      <div class="report-progress">
        <div class="progress-circle">
          <completion-circle
            :size="96"
            :lineSize="6"
            :completed="completed"
            :total="total"
            :hasIcon="true"
          />
        </div>
        <div>{{ $t("your_obstacle_completion") }}</div>
        <div>{{ completed }}/{{ total }}</div>
      </div>
      <div class="aroo-btn">
        <mem-button btn-type="secondary-dark" @click="closeReport">
          AROO
        </mem-button>
      </div>
    </div>
    <!-- Review state desktop-->
    <div
      class="review-list"
      v-if="!showResultsReport && unreportedObstaclesList.length === 0"
    >
      <template v-for="(obstacle, index) in reportedObstacles">
        <div class="obstacle-item" :key="index">
          <div
            class="preview"
            :style="{
              'background-image': `url(${obstacle.image_url_thumb})`,
            }"
          >
            <div class="obstacle-status">
              <div class="status-bg">
                <i
                  v-if="obstacle.is_passed"
                  class="material-icons"
                  style="color: #5ac43b"
                  >done</i
                >
                <i
                  v-if="!obstacle.is_passed"
                  class="material-icons"
                  style="color: #cc2930"
                  >close</i
                >
              </div>
            </div>
          </div>
          <div class="hover-view">
            <div class="mem-font--obstacle-card__hover-title obs-name">
              {{ $t(obstacle.name_i18n_key) }}
            </div>
            <div class="obs-complition">
              {{ obstacle.pct_passed + "% " + $t("completed") }}
            </div>
            <div class="obs-buttons">
              <div
                class="obs-button"
                :class="[obstacle.is_passed ? 'is-active' : '']"
                @click="submitReport(obstacle, true)"
              >
                <i class="material-icons" style="color: #5ac43b">done</i>
              </div>
              <div
                class="obs-button"
                :class="[obstacle.is_passed ? '' : 'is-active']"
                @click="submitReport(obstacle, false)"
              >
                <i class="material-icons" style="color: #cc2930">close</i>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- Review state mobile-->
    <div
      v-if="!showResultsReport && unreportedObstaclesList.length === 0"
      class="review-mobile"
    >
      <div class="mobile-list">
        <template v-for="(obstacle, index) in reportedObstacles">
          <div :key="index" class="mobile-card">
            <div
              class="card-image"
              :style="{
                'background-image': `url(${obstacle.image_url_small})`,
              }"
            >
              <div class="obstacle-status">
                <div class="status-bg">
                  <i
                    v-if="obstacle.is_passed"
                    class="material-icons"
                    style="color: #5ac43b"
                    >done</i
                  >
                  <i
                    v-if="!obstacle.is_passed"
                    class="material-icons"
                    style="color: #cc2930"
                    >close</i
                  >
                </div>
              </div>
            </div>
            <div
              class="card-line"
              :style="{
                background: obstacle.is_passed ? '#5AC43B' : '#CC2930',
              }"
            ></div>
            <div class="card-description">
              <div class="mem-font--obstacle-card__mobile-title desc-title">
                {{ $t(obstacle.name_i18n_key) }}
              </div>
              <div class="desc-subline">
                {{ obstacle.pct_passed + "% " + $t("completed") }}
              </div>
              <div class="desc-buttons">
                <div
                  class="desc-button"
                  :style="{ opacity: obstacle.is_passed ? 0.2 : 1 }"
                  @click="submitReport(obstacle, false)"
                >
                  <i class="material-icons" style="color: #cc2930">close</i>
                </div>
                <div
                  class="desc-button"
                  :style="{ opacity: obstacle.is_passed ? 1 : 0.2 }"
                  @click="submitReport(obstacle, true)"
                >
                  <i class="material-icons" style="color: #5ac43b">done</i>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import CompletionCircle from "./CompletionCircle.vue";
import ObstacleCard from "./ObstacleCard.vue";

export default {
  components: {
    CompletionCircle,
    ObstacleCard,
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    showResultsReport: false,
    currentObsctacleIndex: 0,
  }),
  computed: {
    ...mapGetters(["unreportedObstaclesList", "reportedObstacles"]),
    completed() {
      let passedObstacles = _.filter(this.reportedObstacles, {
        is_passed: true,
      });
      return passedObstacles.length;
    },
    total() {
      return this.reportedObstacles.length;
    },
    currentObsctacle() {
      return this.unreportedObstaclesList[this.currentObsctacleIndex];
    },
  },
  props: ["eventId"],
  methods: {
    ...mapActions(["reportObstacle", "getObstaclesList"]),
    accept() {
      this.swipeList("right");
    },
    deny() {
      this.swipeList("left");
    },
    swipeList(side) {
      document.getElementById("item-0")?.classList.add(`card-swipe__${side}`);
      // timeout fn to show animation
      setTimeout(() => {
        document
          .getElementById("item-0")
          ?.classList.remove(`card-swipe__${side}`);
        document
          .getElementById("item-1")
          ?.classList.remove("card-move__forward");
        document
          .getElementById("item-2")
          ?.classList.remove("card-move__middle");
        document
          .getElementById("item-3")
          ?.classList.remove("card-move__form-nowhere");

        this.reportObstacle({
          obstacle: this.currentObsctacle,
          isPassed: side === "right",
          eventId: this.eventId,
        });

        if (this.unreportedObstaclesList.length === 0)
          this.showResultsReport = true;
      }, 300);
      document.getElementById("item-1")?.classList.add("card-move__forward");
      document.getElementById("item-2")?.classList.add("card-move__middle");
      document
        .getElementById("item-3")
        ?.classList.add("card-move__form-nowhere");
    },
    closeReport() {
      this.showResultsReport = false;
    },
    submitReport(obstacle, status) {
      console.log("submit");
      this.reportObstacle({
        obstacle: obstacle,
        isPassed: status,
        eventId: this.eventId,
      });
    },
  },
  async mounted() {
    try {
      await this.getObstaclesList(this.eventId);
    } catch (error) {
      console.log("Can't receive obstacles list. Error:", error);
    }
  },
};
</script>
<style lang="scss" scoped>
.result-obstacles {
  // border-top: 1px solid rgba(255, 255, 255, 0.2);
  max-width: 670px;
  width: 100%;
  .obstacles-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 22px;

    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-transform: capitalize;

    .completion-progress {
      display: inherit;
      align-items: inherit;
      .progress-counter {
        font-size: 14px;
        font-weight: bold;
        line-height: 17px;
        color: #676666;
        // letter-spacing: 0.0025em;
      }
      .progress-circle {
        margin-left: 20px;
        display: inherit;
      }
    }
    // @media screen and (max-width: 499px) {
    //   padding: 22px 16px;
    // }
  }
  .reporting-view {
    padding: 2px 0 90px 0;
    .cards-wrap {
      height: 400px;
      position: relative;
      .disider {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 4;
        .disider-btn {
          height: 54px;
          width: 54px;
          border-radius: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          margin: 0 4px;
          cursor: pointer;
          outline: none;
        }
      }

      // move to ObstacleCard component ?
      .card-swipe__right {
        animation: swipe-right 0.3s forwards;
      }
      .card-swipe__left {
        animation: swipe-left 0.3s forwards;
      }
      .card-move__forward {
        animation: scale-full 0.3s forwards;
      }
      .card-move__middle {
        animation: scale-middle 0.3s forwards;
      }
      .card-move__form-nowhere {
        animation: scale-nowhere 0.3s forwards;
      }
    }
  }
  .results-report {
    margin: 2px 0 126px 0;
    height: 350px;
    background: #121212;
    border-radius: 10px;
    padding-bottom: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .report-progress {
      width: 175px;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      text-transform: capitalize;
      text-align: center;
      .progress-circle {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .aroo-btn {
      position: absolute;
      bottom: -29px;
      button {
        height: 54px;
        padding: 0 30px;
        border-radius: 27px;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
  .review-list {
    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
    overflow-x: auto;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #ffffff #1e1e1e;

    padding-top: 48px;
    padding-bottom: 30px;
    margin-bottom: 90px;
    .obstacle-item {
      position: relative;
      z-index: 5;
      height: 100px;
      width: 100px;
      margin-right: 8px;
      border-radius: 4px;
      transition: 150ms transform;
      flex: 0 0 auto;
      .preview {
        transform: scale(1);
        background-size: cover;
        height: 100%;
        border-radius: inherit;
        .obstacle-status {
          height: 30px;
          width: 30px;
          .status-bg {
            background-color: rgba(0, 0, 0, 0.8);
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          i {
            font-weight: bold;
          }
        }
      }
      .hover-view {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        transform: scale(0);
        transition: 150ms transform;

        border-radius: inherit;
        background: #121212;
        .obs-name {
          text-align: center;
          margin-bottom: 2px;
        }
        .obs-complition {
          font-size: 7px;
          line-height: 8px;
          text-transform: uppercase;
          color: #e0e0e0;
        }
        .obs-buttons {
          margin-top: 16px;
          display: flex;
          flex-direction: row;
          .obs-button {
            height: 20px;
            width: 20px;
            border-radius: 100px;
            background: #ffffff;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 1px;

            opacity: 0.2;

            cursor: pointer;
            outline: none;
            i {
              font-size: 14px;
            }
          }
          .is-active {
            opacity: 1;
          }
        }
      }
    }
    .obstacle-item:hover {
      transform: scale(1.25);
      z-index: 20;
      .preview {
        transform: scale(0);
      }
      .hover-view {
        transform: scale(1);
      }
    }
    @media screen and (max-width: 499px) {
      display: none;
    }
  }
  .review-list::-webkit-scrollbar {
    // width: 2px;
    height: 2px;
  }
  .review-list::-webkit-scrollbar-track {
    background: #1e1e1e;
  }
  .review-list::-webkit-scrollbar-thumb {
    background-color: #ffffff; /* color of the scroll thumb */
    // border-radius: 20px; /* roundness of the scroll thumb */
    // border: 3px solid orange; /* creates padding around scroll thumb */
  }
  .review-mobile {
    display: none;
    padding: 22px 0 70px 0;
    .mobile-list {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-left: 18px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      .mobile-card {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        background: #121212;
        border-radius: 10px;
        height: 365px;
        width: 310px;
        margin-right: 16px;
        .card-image {
          border-radius: 10px 10px 0 0;
          background-size: cover;
          height: 180px;
          .obstacle-status {
            height: 62px;
            width: 62px;
            .status-bg {
              background-color: rgba(0, 0, 0, 0.8);
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              i {
                font-weight: bold;
                font-size: 50px;
              }
            }
          }
        }
        .card-line {
          height: 4px;
          width: 100%;
        }
        .card-description {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          .desc-title {
            max-width: 180px;
            text-align: center;

            margin-bottom: 11px;
          }
          .desc-subline {
            font-size: 12px;
            line-height: 14px;
            color: #e0e0e0;
            text-transform: uppercase;
          }
          .desc-buttons {
            display: flex;
            flex-direction: row;
            margin-top: 17px;
            .desc-button {
              height: 42px;
              width: 42px;
              border-radius: 100px;
              background: #ffffff;

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 0 4px;
              i {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 499px) {
    width: 100vw;
    // padding: 0 16px;
    .obstacles-header,
    .reporting-view {
      padding-right: 22px;
      padding-left: 22px;
    }
    .results-report {
      margin: 8px 22px 90px 22px;
    }
    .review-mobile {
      display: flex;
    }
  }
}

@keyframes swipe-right {
  0% {
    transform: translateX(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: translateX(30%) rotate(15deg);
    opacity: 0;
  }
}
@keyframes swipe-left {
  0% {
    transform: translateX(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-30%) rotate(-15deg);
    opacity: 0;
  }
}
@keyframes scale-full {
  0% {
    transform: scale(0.96) translateY(-20px);
  }
  100% {
    transform: scale(1) translateY(0px);
  }
}
@keyframes scale-middle {
  0% {
    transform: scale(0.91) translateY(-40px);
    opacity: 0.3;
  }
  100% {
    transform: scale(0.96) translateY(-20px);
    opacity: 1;
  }
}
@keyframes scale-nowhere {
  0% {
    transform: scale(0.91) translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: scale(0.91) translateY(-40px);
    opacity: 0.3;
  }
}
</style>
