<template>
  <mem-button v-if="showButton" @click="findRace">{{ $t(text) }}</mem-button>
</template>
<script>
import { mapGetters } from "vuex";
import { Localizer, UrlPath } from "@/common/helpers";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["userData"]),
    countryConfig() {
      let country = this.userData.spartan_country_code;
      let lang = this.userData.language;
      return Localizer.getLocalizeConfig(country, lang);
    },
    showButton() {
      return this.userData.spartan_country_code !== "SE";
    },
  },
  props: {
    text: {
      type: String,
      default: "find_race",
    },
  },
  components: {
    "mem-button": () => import("./base/BaseButton.vue"),
  },
  methods: {
    findRace() {
      let link;
      if (UrlPath.isRelative(this.countryConfig.findRaceUrl))
        link = `${this.countryConfig.homeUrl}${this.countryConfig.findRaceUrl}`;
      else link = this.countryConfig.findRaceUrl;
      window.location = link;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>